import React, { useEffect, useState } from 'react'

import { PreviewCardHeader } from '../../../Common'
import { Card, CardBody, Col, Container, Form, Label, Row } from 'reactstrap'

const FilterForm = ({
  projectsData,
  golfCoursesData,
  filterHandler,
  filterValues,
  downloadDump,
}) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    projectName: '',
    golfCourse: '',
    fromDate: '',
    toDate: '',
    status: '',
  }
  const [filter, setFilter] = useState(initialState)
  const [isFilterDisabled, setIsFilterSelected] = useState(true)
  const [isResetDisabled, setIsResetDisabled] = useState(true)

  useEffect(() => {
    setFilter(filterValues)
    checkValidation(filterValues)
  }, [filterValues])

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const checkValidation = (filterData) => {
    if (filterData.projectName === '') {
      setIsFilterSelected(true)
      setIsResetDisabled(true)
      setFilter(initialState)
    } else {
      setIsFilterSelected(false)
      if (
        filterData.golfCourse === '' &&
        filterData.fromDate === '' &&
        filterData.toDate === '' &&
        filterData.status === ''
      ) {
        setIsResetDisabled(true)
      } else {
        setIsResetDisabled(false)
      }
    }
  }

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e) => {
    const { name, value } = e.target

    let filterData = null
    switch (name) {
      case 'projectName':
        setFilter({ ...filter, projectName: value })
        filterData = { ...filter, projectName: value }
        break

      case 'golfCourse':
        setFilter({ ...filter, golfCourse: value })
        filterData = { ...filter, golfCourse: value }
        break

      case 'fromDate':
        setFilter({ ...filter, fromDate: value })
        filterData = { ...filter, fromDate: value }
        break

      case 'toDate':
        setFilter({ ...filter, toDate: value })
        filterData = { ...filter, toDate: value }
        break

      case 'status':
        setFilter({ ...filter, status: value })
        filterData = { ...filter, status: value }
        break

      default:
      // do nothing
    }
    checkValidation(filterData)
  }

  const filterSubmitHandler = () => {
    // console.log('filter',filter)
    filterHandler(filter)
  }

  /* ---------------------------- RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({
      golfCourse: '',
      fromDate: '',
      toDate: '',
      status: '',
    })

    setIsResetDisabled(true)
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col xxl={12}>
            <Card>
              <PreviewCardHeader title="Filter" />
              <CardBody>
                <div className="live-preview">
                  <Form>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label
                            htmlFor="ForminputState"
                            className="form-label"
                          >
                            Projects
                          </Label>
                          <select
                            className="form-select"
                            name="projectName"
                            value={filter.projectName}
                            onChange={inputChangeHandler}
                          >
                            <option value="">Choose...</option>
                            {projectsData?.map((project, index) => (
                              <option value={project?.objectId} key={index}>
                                {project?.project_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">Golf Course</Label>
                          <select
                            className="form-select"
                            name="golfCourse"
                            value={filter.golfCourse}
                            onChange={inputChangeHandler}
                            disabled={isFilterDisabled}
                          >
                            <option value="">Choose...</option>
                            {golfCoursesData?.map((golfCourse, index) => (
                              <option value={golfCourse?.objectId} key={index}>
                                {golfCourse?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>

                      {/* <Col md={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                          >
                            From Date (DD/MM/YYYY)
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            name="fromDate"
                            value={filter.fromDate}
                            onChange={inputChangeHandler}
                            disabled={isFilterDisabled}
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                          >
                            To Date (DD/MM/YYYY)
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            name="toDate"
                            value={filter.toDate}
                            onChange={inputChangeHandler}
                            disabled={isFilterDisabled}
                          />
                        </div>
                      </Col> */}

                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">Status</Label>
                          <select
                            className="form-select"
                            name="status"
                            value={filter.status}
                            onChange={inputChangeHandler}
                            disabled={isFilterDisabled}
                          >
                            <option value="">Choose...</option>
                            <option value="PENDING">Pending</option>
                            <option value="PROCESSING">Processing</option>
                            <option value="CONFIRMED">Confirmed</option>
                            <option value="CANCELLED BY USER">
                              Cancel By User
                            </option>
                            <option value="CANCELLED BY THRIWE">
                              Cancel By Thriwe
                            </option>
                            <option value="Completed">Completed</option>
                            <option value="Payment Awaited">
                              Payment Awaited
                            </option>
                            <option value="Recommended">Recommended</option>
                            <option value="Acknowledged">Acknowledged</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <Row>
                  <Col md={6} className="p-0">
                    <div className="start">
                      {/* <button
                        type="submit"
                        className="btn btn-primary mx-3 overlay-disable"
                        onClick={downloadDump}
                      >
                        Download Dump
                      </button> */}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success mx-3 overlay-disable"
                        onClick={downloadDump}
                        disabled={isFilterDisabled}
                      >
                        Download Dump
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary mx-3 overlay-disable"
                        onClick={filterSubmitHandler}
                        disabled={isFilterDisabled}
                      >
                        Filter
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary overlay-disable"
                        onClick={resetFilterHandler}
                        disabled={isResetDisabled}
                      >
                        Reset Filter
                      </button>
                    </div>
                  </Col>
                </Row>

                {/* <Col md={12}>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn btn-primary mx-3 overlay-disable"
                      onClick={filterSubmitHandler}
                      disabled={isFilterDisabled}
                    >
                      Filter
                    </button>

                    <button
                      type="submit"
                      className="btn btn-primary overlay-disable"
                      onClick={resetFilterHandler}
                      disabled={isResetDisabled}
                    >
                      Reset Filter
                    </button>
                  </div>
                </Col> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default FilterForm
