import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Papa from "papaparse";
import { saveAs } from "file-saver";
import moment from "moment";
import cogoToast from "cogo-toast";
import { Container } from "reactstrap";

import { BreadCrumb, DownloadDump, Loader, Paginate } from "../../Common/index";
import {
  GET_PROJECTS,
  GET_GOLF_COURSES,
  GET_GOLF_BOOKING,
  UPDATE_GOLF_BOOKING,
  EMPTY_UPDATE_GOLF_BOOKING,
  SEND_MAIL,
  GET_GOLF_BOOKING_BY_ID,
} from "../../../store/application/actionType";
import {
  Table,
  FilterForm,
  CancelModal,
  UpdateModal,
  BookingDetails,
  DumpModal,
} from "./Components/index";

const GolfBooking = () => {
  const initialObj = {
    fromDate: '',
    toDate: '',
  }
  const [dateRange, setDateRange] = useState(initialObj)
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
   
  const [isShowBookingDetails, setIsShowBookingDetails] = useState(false);

  const [isUpdateModalDisplay, setIsUpdateModalShow] = useState(false);
  const [isUpdateModalData, setIsUpdatedModalData] = useState(null);

  const [isCancelModalDisplay, setIsCancelModalShow] = useState(false);
  const [isCancelModalData, setIsCancelModalData] = useState(null);

  const [isDumpModalDisplay, setIsDumpModalDisplay] = useState(false);
  const [isDumpModalData, setIsDumpModalData] = useState(null);

  const [golfBookingData, setGolfBookingData] = useState([]);
  const [updatedGolfBookingData, setUpdatedGolfBookingData] = useState([]);

  const [viewGolfBookingDetails, setViewGolfBookingDetails] = useState(null);

  const [currentBookingData, setCurrentBookingData] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [isDownloadDump, setIsDownloadDump] = useState(false);
  const [downloadDumpData, setDownloadDumpData] = useState([
    { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
    { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" },
  ]);

  const [appliedFilter, setAppliedFilter] = useState({
    projectName: "",
    golfCourse: "",
    fromDate: "",
    toDate: "",
    status: "",
  });

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { projects, projectsLoading, projectsError } = useSelector((state) => ({
    projects: state?.AllProjects?.data,
    projectsLoading: state?.AllProjects?.loading,
    projectsError: state?.AllProjects?.error,
  }));

  const { golfCourses, golfCoursesLoading, golfCoursesError } = useSelector(
    (state) => ({
      golfCourses: state?.AllGolfCourses?.data,
      golfCoursesLoading: state?.AllGolfCourses?.loading,
      golfCoursesError: state?.AllGolfCourses?.error,
    })
  );

  const { golfBookings, golfBookingsLoading, golfBookingError } = useSelector(
    (state) => ({
      golfBookings: state?.GolfBookings?.data?.data,
      golfBookingsLoading: state?.GolfBookings?.loading,
      golfBookingError: state?.GolfBookings?.error,
    })
  );

  const {
    GetGolfBookingById,
    GetGolfBookingByIdLoading,
    golfBGetGolfBookingByIdErrorookingError,
  } = useSelector((state) => ({
    GetGolfBookingById: state?.GetGolfBookingById?.data,
    GetGolfBookingByIdLoading: state?.GetGolfBookingById?.loading,
    GetGolfBookingByIdError: state?.GetGolfBookingById?.error,
  }));

  const {
    golfBookingStatusData,
    golfBookingStatusLoading,
    golfBookingStatusError,
  } = useSelector((state) => ({
    golfBookingStatusData: state?.UpdateGolfBooking?.data,
    golfBookingStatusLoading: state?.UpdateGolfBooking?.loading,
    golfBookingStatusError: state?.UpdateGolfBooking?.error,
  }));

  const { dumpDownloadData, dumpDownloadLoading, dumpDownloadError } =
    useSelector((state) => ({
      dumpDownloadData: state?.DownloadDump?.data,
      dumpDownloadLoading: state?.DownloadDump?.Loading,
      dumpDownloadError: state?.DownloadDump?.Error,
    }));

      // Create Benefit Group Items
   const { downloadDumpData1, downloadDumpDataLoading } = useSelector(state => ({
    downloadDumpData1: state?.DownloadDump?.data,
    downloadDumpDataLoading: state?.DownloadDump?.loading,
  }));

    function createCSV(data){
      const headers = [
        "S.No.",
        "Booking id",
        "Order id",
        "Payment id",
        "First Name",
        "Last Name",
        "Phone",
        "Email",
        "Bogo Offer",
        "Guest Name",
        "Golf Course Name",
        "Created At",
        "Updated At",
        "Date of Play",
        "Time of Play",
        "Golf Address",
        "Golf Country",
        "Golf Region",
        "Status",
      ];
      let csvData=[];
        data?.forEach((item,i) => {
          let tmp={
            
              "S.No.": ++i,
              "Booking id": item?.objectId,
              "Order id": item?.order_id,
              "Payment id": item?.payment.card.last_four,
              "First Name": item?.user.first_name,
              "Last Name": item?.user.last_name,
              "Phone": `${item?.user.country_code} ${item?.user.phone}`,
              "Email": item?.user.email,
              "Bogo Offer":item?.isGuest ? "YES" :"NO",// Placeholder if no item provided
              "Guest Name": item?.isGuest ? item?.guestName :"-", // Placeholder if no item provided
              "Golf Course Name": item?.golf_course.preferred.name,
              "Created At": new Date(item?.createdAt).toLocaleString(),
              "Updated At": new Date(item?.updatedAt).toLocaleString(),
              "Date of Play": item?.date_of_play.preferred,
              "Time of Play": item?.time_of_play.preferred,
              "Golf Address": item?.golf_course.preferred.address,
              "Golf Country": item?.golf_course.preferred.country.name,
              "Golf Region": item?.golf_course.preferred.region.name,
              "Status": item?.status,
              
            }
            csvData.push(tmp)
          });
            
          // console.log('csvData', csvData);
        const csv = Papa.unparse({
          fields: headers,
          data: csvData.map((row) => headers.map((header) => row[header] || "")),
        });
    
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `booking_dump_from_${dateRange.fromDate}_to_${dateRange.toDate}.csv`);
    }

  useEffect(() => {
    if (downloadDumpData1?.length > 0) {
      createCSV(downloadDumpData1)
    }
  }, [downloadDumpData1])

  /* ---------------------------- SCROLL TO TOP ---------------------------- */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [isShowBookingDetails]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_PROJECTS,
    });
    dispatch({
      type: GET_GOLF_COURSES,
    });
  }, [dispatch]);

  /* ---------------------------- FILTER HANDLER ---------------------------- */
  const filterHandler = (filterValues) => {
    setAppliedFilter(filterValues);
    console.log('filterValues', filterValues);
    let statusFilter='';
    let gcFilter='';

    if(filterValues.golfCourse)gcFilter=`,"golf_course.preferred.id":{"$in":["${filterValues.golfCourse}"]}`;
    if(filterValues.status)statusFilter=`,"status":{"$in":["${filterValues.status}"]}`;

    dispatch({
      type: GET_GOLF_BOOKING,
      payload: {
        param: `{"status": {"$nin": ["INITIATED"]}${statusFilter}${gcFilter}}&limit=10&skip=0&count=1&order=-createdAt`,
       
      },
    });
  };

  useEffect(() => {
    if (dumpDownloadData !== null) {
      console.log("inside dump", dumpDownloadData);
      //   const url = window.URL.createObjectURL(new Blob([response.data]))
      //   const link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', `${Date.now()}.xlsx`)
      //   document.body.appendChild(link)
      //   link.click()
    }
  }, [dumpDownloadData]);

  useEffect(() => {
    if (golfBookingStatusData !== null) {
      cogoToast.success(`${`Action Completed`}`);

      dispatch({
        type: EMPTY_UPDATE_GOLF_BOOKING,
      });

      dispatch({
        type: GET_GOLF_BOOKING_BY_ID,
        payload: { id: `${isCancelModalData?.["objectId"]}` },
      });
    }
  }, [dispatch, golfBookingStatusData]);

  /* ---------------------------- UPDATE BOOKING BY ID HANDLER ---------------------------- */
  useEffect(() => {
    if (GetGolfBookingById !== null) {
      const needToUpdateAtIndex = golfBookings?.results
        ?.map((booking, i) => {
          if (booking?.objectId === GetGolfBookingById?.objectId) {
            return i;
          } else return null;
        })
        ?.filter((data) => data !== null)?.[0];

      setUpdatedGolfBookingData(
        golfBookings?.results?.splice(
          needToUpdateAtIndex,
          1,
          GetGolfBookingById
        )
      );
    }
  }, [GetGolfBookingById]);

  /* ---------------------------- PARSING TABLE DATA ---------------------------- */
  useEffect(() => {
    if (golfBookings?.results?.length > 0) {
      const parsedBookingData = golfBookings?.results?.map((booking, i) => {
        return [
          `${currentPageNumber * 10 + (i + 1)}.`,
          booking?.objectId || "NA",
          booking?.order_id || "NA",
          booking?.payment?.id || "NA",
          booking?.user?.first_name || "NA",
          booking?.user?.last_name || "NA",
          booking?.user?.phone || "NA",
          booking?.user?.email || "NA",
          booking?.isGuest ? "YES" : "NO",
          booking?.guestName || "NA",
          booking?.golf_course?.preferred?.name || "NA",
          booking?.createdAt
            ? moment(`${booking?.createdAt}`).format("DD/MM/YYYY, h:mm:ss a")
            : "NA",
          booking.status === `PENDING`
            ? booking?.createdAt
              ? moment(`${booking?.createdAt}`).format("DD/MM/YYYY, h:mm:ss a")
              : "NA"
            : booking?.updatedAt
            ? moment(`${booking?.updatedAt}`).format("DD/MM/YYYY, h:mm:ss a")
            : "NA",

          booking?.date_of_play?.preferred
            ? moment(`${booking?.date_of_play?.preferred}`).format("DD/MM/YYYY")
            : "NA",
          booking?.time_of_play?.preferred
            ? moment(`${booking?.time_of_play?.preferred}`, "HHmmss").format(
                "h:mm:ss a"
              )
            : "NA",
          booking?.golf_course?.preferred?.address || "NA",
          booking?.golf_course?.preferred?.country?.name || "NA",
          booking?.golf_course?.preferred?.region?.name || "NA",
          booking.status || "NA",
          booking || "NA",
        ];
      });

      setGolfBookingData(
        parsedBookingData?.length > 0 ? parsedBookingData : []
      );
    }else{
      setGolfBookingData(
        []
      );
    }
  }, [golfBookings, updatedGolfBookingData]);

  /* ---------------------------- TABLE BUTTON ACTIONS HANDLER ---------------------------- */
  const tableButtonClickHandler = (data) => {
    const selectedGolfBooking = golfBookings?.results?.filter(
      (booking) => booking?.objectId === data?.id
    )?.[0];

    if (data?.status === `VIEW`) {
      setViewGolfBookingDetails(selectedGolfBooking);
      setIsShowBookingDetails(true);
    } else if (data?.status === `CANCELLED`) {
      setIsCancelModalShow(true);
      setIsCancelModalData(selectedGolfBooking);
    } else if (data?.status === `CONFIRMED`) {
      dispatch({
        type: UPDATE_GOLF_BOOKING,
        payload: {
          bookingId: data?.id,
          payloadData: { status: "CONFIRMED" },
        },
      });

      const mailPayload = {
        userId: selectedGolfBooking?.user?.id,
        bookingId: selectedGolfBooking?.objectId,
        templateCode: "email_05",
        emailId: selectedGolfBooking?.user?.email,
      };

      dispatch({
        type: SEND_MAIL,
        payload: {
          payloadData: mailPayload,
        },
      });
    } else if (data?.status === `UPDATE`) {
      setIsUpdateModalShow(true);
      setIsUpdatedModalData(golfBookingData[data - 1]);
    } else if (data?.status === `PROCESSED`) {
      setIsCancelModalData(selectedGolfBooking);

      dispatch({
        type: UPDATE_GOLF_BOOKING,
        payload: {
          bookingId: data?.id,
          payloadData: { status: "PROCESSED" },
        },
      });
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    setIsDumpModalDisplay(true);
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
    dispatch({
      type: GET_GOLF_BOOKING,
      payload: {
        param: `{"status": {"$nin": ["INITIATED"]}}&count=1&limit=10&skip=${
          pageNumber * 10
        }&order=-createdAt`,
      },
    });
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = (title) => {
    if (title === `Dashboard`) {
      history.push("/dashboard");
    } else if (title === `Golf Booking`) {
      setIsShowBookingDetails(false);
    } else if (title === `"Information"`) {
      // do nothing
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    console.log({ dumpDownloadLoading });
    if (
      projectsLoading ||
      golfCoursesLoading ||
      golfBookingsLoading ||
      golfBookingsLoading ||
      GetGolfBookingByIdLoading ||
      golfBookingStatusLoading ||
      dumpDownloadLoading ||downloadDumpDataLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    projectsLoading,
    golfCoursesLoading,
    golfBookingsLoading,
    GetGolfBookingByIdLoading,
    golfBookingStatusLoading,
    dumpDownloadLoading,
    downloadDumpDataLoading
  ]);

  console.log("projectsprojects", golfBookings);

  return (
    <React.Fragment>
      {loading && <Loader />}
      {isDownloadDump && <DownloadDump data={downloadDumpData} />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {isUpdateModalDisplay && (
            <UpdateModal
              data={isUpdateModalData}
              onCloseHandler={() => setIsUpdateModalShow(false)}
            />
          )}
          {isCancelModalDisplay && (
            <CancelModal
              data={isCancelModalData}
              onCloseHandler={() => setIsCancelModalShow(false)}
            />
          )}
          {isDumpModalDisplay && (
            <DumpModal onCloseHandler={() => setIsDumpModalDisplay(false)} dateRange={dateRange}  setDateRange={setDateRange}/>
          )}

          {!isShowBookingDetails ? (
            <>
              <BreadCrumb
                title="Golf Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={["Dashboard", "Golf Booking"]}
              />
              <FilterForm
                projectsData={projects}
                golfCoursesData={golfCourses}
                filterHandler={filterHandler}
                filterValues={appliedFilter}
                downloadDump={downloadDumpHandler}
              />
              <Table
                headingPreview={"Booking Data"}
                totalData={golfBookings?.count}
                data={golfBookingData}
                onClickHandler={tableButtonClickHandler}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
              />
            </>
          ) : (
            <>
              <BreadCrumb
                title="Golf Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={["Dashboard", "Golf Booking", "Information"]}
              />
              <BookingDetails details={viewGolfBookingDetails} />
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GolfBooking;
